<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" >
        <form-config-global
                    v-if="isShowModal"
                    :show-modal.sync="isShowModal"
                    :modal-data="dataConfig"
        ></form-config-global>
        <div class="kt-portlet kt-portlet--mobile">
       <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="'Payment Config Global'"></base-title>
          <div class="kt-portlet__head-toolbar">
          </div>
        </div>
        <div  class="kt-portlet__body">
          <!-- content Config Global -->
          <table class="table table-bordered">
              <thead>
                  <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <th>Action</th>
                  </tr>
              </thead>
              <tbody v-if="isLoadingConfig">
                  <tr v-for="item, key in defaultConfigGlobal" :key="key">
                      <td>{{item.key}}</td>
                       <td>{{item.value}}</td>
                       <td>
                           <button
                        @click="editValue(item)"
                        class="btn btn-sm btn-clean btn-icon btn-icon-md"
                        title="Edit"
                    >
                        <i class="la la-pencil-square"></i>
                    </button>
                       </td>
                  </tr>
              </tbody>
          </table>
        </div>
        </div>
  </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import LoadSpinner from "./../../../components/_general/LoadSpinner";
import {mapState, mapActions} from 'vuex'
export default {
    components: {
        BaseTitle,
        FormConfigGlobal: () => ({
            component: new Promise(function (resolve, reject) {
                setTimeout(function () {
                resolve(
                    import(
                      "./FormConfigGlobal.vue"
                    )
                );
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    computed:{
        ...mapState('PaymentStore', {
            defaultConfigGlobal: state => state.defaultConfigGlobal1,
            isLoadingConfig: state => state.isLoadingConfigGlobal
        })
    },
    mounted() {
        this.getConfigPayment()
    },
    data() {
        return {
            isShowModal: false,
            dataConfig: {}
        }
    },
    methods: {
     
        editValue(item) {
            this.dataConfig = item
            this.isShowModal = true
        },
        ...mapActions({
            getConfigPayment: 'PaymentStore/GET_CONFIG_PAYMENT_GLOBAL'
        })
    }
}
</script>

<style>

</style>