<template>
  <div>
    <base-header base-title="Payment Management"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="'Payment Management'"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
                <base-button
                  :button-text="'Tambah Payment'"
                  :button-icon="'flaticon2-add-1'"
                  :onButtonModalClicked="goPayment"
                ></base-button>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body" v-if="isSetDefault">
          <table class="table table-bordered " v-if="loading && !loadingAPI">
            <thead>
              <tr>
                <th>ID</th>
                <th>Icon</th>
                <th>Nama</th>
                <th>Deskripsi</th>
                <th>Actions</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th><input type="text" class="form-control" v-model="nama"></th>
                <th><input type="text" class="form-control" v-model="deskripsi"></th>
                <th></th>
              </tr>
            </thead>
            <tbody  v-for="(item, index) in paymentMethod" :key="item.id">
              <tr>
                <td class="align-middle">{{item.id}}</td>
                <td class="align-middle p-0 text-center p-2"><img  :src="SetImage(item.icon)" @error="$event.target.src=defaultImage"></td>
                <td class="align-middle">{{item.name}}</td>
                <td class="align-middle">{{item.description}} </td>
                <td class="align-middle">
                  <div class="text-right">
                  <button
                      v-if="item.collapse"
                      @click="checkData(index)"
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      title="View"
                  >
                      <i class="la la-eye"></i>
                  </button>
                    <button
                      @click="goPayment(item)"
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      title="Edit"
                  >
                      <i class="la la-pencil-square"></i>
                  </button>
                   <button
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      title="Hapus"
                      @click="deleteWithId(item)"
                      :disabled="item.hasChild"
                     
                  >
                      <i class="la la-trash"></i>
                  </button>
                  </div>
                </td>
              </tr>
              <template v-if="item.children && item.collapse == 2">
                <tr v-for="item2 in item.children" :key="item2.id" class="bg-secondary"> 
                  <td class="align-middle text-center">{{item2.id}}</td>
                  <td class="align-middle p-2 text-right "> <img  :src="SetImage(item2.icon)" @error="$event.target.src=defaultImage" alt=""></td>
                  <td class="align-middle pl-5">{{item2.name}}</td>
                  <td class="align-middle pl-4">{{item2.description}}</td>
                  <td class="align-middle">
                    <div class="text-right">
                      <button
                        @click="goPayment(item2)"
                        class="btn btn-sm btn-clean btn-icon btn-icon-md"
                        title="Edit"
                    >
                        <i class="la la-pencil-square"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      title="Hapus"
                      @click="deleteWithId(item2)"
                  >
                      <i class="la la-trash"></i>
                  </button>
                    </div>
                  </td>
              </tr>
              </template>
            </tbody>
          </table>
          <div v-if="loadingAPI" class="m-auto p-5 d-flex flex-row h-100">
              <div
                  class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
              ></div>
              <h5 class="my-auto pl-5">Loading ...</h5>
          </div>
        </div>
        <div  class="kt-portlet__body" v-else>
          <table class="table table-bordered " v-if="loading && !loadingAPI">
            <thead>
              <tr>
                <th>ID</th>
                <th>Icon</th>
                <th>Nama</th>
                <th>Deskripsi</th>
                <th>Actions</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th><input type="text" class="form-control" v-model="nama"></th>
                <th>
                  <input type="text" class="form-control" v-model="deskripsi">
                  </th>
                <th></th>
              </tr>
            </thead>
            <tbody  v-for="(item) in paymentMethodSearch" :key="item.id">
              <tr :class="item.parentId ? 'bg-secondary': ''">
                <td class="align-middle">{{item.id}}</td>
                <td class="align-middle p-2 text-center"><img :src="SetImage(item.icon)" @error="$event.target.src=defaultImage"></td>
                <td class="align-middle">{{item.name}}</td>
                <td class="align-middle">{{item.description}} </td>
                <td class="align-middle">
                  <div class="text-right">
                    <button
                      @click="goPayment(item)"
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      title="Edit"
                  >
                      <i class="la la-pencil-square"></i>
                  </button>
                   <button
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      title="Hapus"
                      @click="deleteWithId(item)"
                      :disabled="item.hasChild"
                     
                  >
                      <i class="la la-trash"></i>
                  </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <config-payment-global />
  </div>
</template>

<script>
import BaseTitle from "./../../components/_base/BaseTitle";
import BaseHeader from "./../../components/_base/BaseHeader";
import BaseButton from "./../../components/_base/BaseButton";
import { mapState, mapMutations, mapActions } from "vuex";
import ConfigPaymentGlobal from './payment_global/ConfigPaymentGlobal.vue'
// import noImage from '../assets/no-payment.png'
import noImage from '../assets/default-bank.svg'

export default {
  components: {
    BaseTitle,
    BaseHeader,
    BaseButton,
    ConfigPaymentGlobal
  },
  computed: {
    ...mapState('PaymentStore', {
      loadingAPI: state => state.loadingAPI,
      paymentMethod: state => state.paymentMethod,
      listPaymentGlobal: state => state.listPaymentGlobal
    })
  },
  data() {
    return {
      loading: true,
      defaultImage: noImage,
      nama: '',
      deskripsi: '',
      timeOutSearch: '',
      isSetDefault: true,
      paymentMethodSearch: []
    }
  },
  watch: {
    nama(data) {
      clearTimeout(this.timeOutSearch)
      this.timeOutSearch = setTimeout(()=> {
        const deskripsi = this.deskripsi
        if(data.length == 0 && deskripsi.length == 0) {
           this.isSetDefault = true
        } else {
          this.isSetDefault = false
          this.paymentMethodSearch = this.listPaymentGlobal.filter(item => item.name.includes(data) && item.description.includes(deskripsi))
        }
      }, 1000)
    },
    deskripsi(data) {
     
       clearTimeout(this.timeOutSearch)
        this.timeOutSearch = setTimeout(()=> {
        const name = this.nama
        if(data.length == 0 && name.length == 0) {
           this.isSetDefault = true
        } else {
          this.isSetDefault = false
          this.paymentMethodSearch = this.listPaymentGlobal.filter(item => item.name.includes(name) && item.description.includes(data))
        }
      }, 1000)
    }
  },
  methods: {
    showDetail(value, key) {

    },
    goPayment(data) {
      if(data) {
        this.setPaymentDetail(data)
        this.$router.push({
              name: "payment-global-edit",
              params: { id : data.id }
        });
      } else {
        // go to add
        this.setPaymentDetail({})
        this.$router.push({ name: "payment-global-add" });
      }
    },
     ...mapMutations({
          setPaymentDetail: "PaymentStore/SET_PAYMENT_DETAIL"
      }),
      ...mapActions({
        getAllPaymentMethods: 'PaymentStore/GET_ALL_PAYMENT_METHODS',
        deletePaymentMethods: 'PaymentStore/DELETE_PAYMENT_METHOD'
      }),
      checkData(index) {
        this.loading = false
        const collapse = this.paymentMethod[index].collapse
        this.$set(this.paymentMethod[index], 'collapse', collapse == 1 ? 2 : 1)
        this.loading = true

      },
      deleteWithId(item) {
        swal({
          title: 'Apakah Yakin?',
          text: "Menghapus Metode Payment",
          type: 'info',
          showCancelButton: true,
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.value) {
          //  this.save()
          this.deletePaymentMethods(item.id)
          }
        })
      },
      SetImage(icon) {
          // return  "data:image/png;base64," + icon
          return  "data:image/svg+xml;base64," + icon
      },
  },
  mounted() {
     this.getAllPaymentMethods()
  }
};
</script>

<style>
</style>